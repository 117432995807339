import images from "../images/images.jpg";
import images_1 from "../images/images_1.jpg";
import images_2 from "../images/images_2.jpg";
import images_3 from "../images/images_3.jpg";

const slider = [
{ "id": 1, "value": "card-1", "img": images }, 
{ "id": 2, "value": "card-2", "img": images_1 },
{ "id": 3, "value": "card-3", "img": images_2 }, 
{ "id": 3, "value": "card-3", "img": images_3 }, 
{ "id": 1, "value": "card-1", "img": images },
{ "id": 2, "value": "card-2", "img": images_1 }, 
{ "id": 3, "value": "card-3", "img": images_2 }, 
{ "id": 3, "value": "card-3", "img": images_3 }
]
export default slider