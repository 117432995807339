import React,{useState} from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Button, CardFooter, Input, Collapse, Container, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledCollapse,
} from "reactstrap"
import classnames from "classnames";
import EmiImg from '../../images/emiCalculator.avif'
import EmiCal from "../../images/emiCal.webp"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { BrowserView, MobileView } from 'react-device-detect';
import { VictoryPie } from 'victory';
import CalResultDialogue from "./CalResultDialogue";

const Calculator = (props) => {
    const { loanAmount, setLoanAmount, interestRate, setInterestRate, loanTerm, setLoanTerm, callBackCalculator,
        amountLabels, rateLabels, termLabels, investmentName, resultAmount, setResultAmount, chartData, setChartData, calculatedData } = props

    const [isOpen,setIsOpen]=useState(false)

    return (
        <div>
            <BrowserView>
                <Row>
                    <Col className="col-8" style={{ padding: "0" }}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <div className="p-3">
                                            <h5 className="font-size-14 mb-3 mt-0">
                                                {investmentName === 'EMI' ? 'Loan Amount:' : 'Principal Amount:'}
                                            </h5>
                                            <Input type="number" name="amount" placeholder="Enter Amount" value={loanAmount} onChange={(e) => {
                                                setResultAmount(0)
                                                setChartData(null)
                                                setLoanAmount(e.target.value)
                                            }}
                                            />
                                            <Slider
                                                value={loanAmount / 100000}
                                                min={0}
                                                max={200}
                                                step={5}
                                                marks={amountLabels}
                                                vertical={false}
                                                onChange={value => {
                                                    setResultAmount(0)
                                                    setChartData(null)
                                                    setLoanAmount(value * 100000)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="p-3">
                                            <h5 className="font-size-14 mb-3 mt-0">
                                                Interest Rate (% per annum):
                                            </h5>
                                            <Input type="number" name="interestRate" placeholder="Enter Interest Rate" value={interestRate} onChange={(e) => {
                                                setResultAmount(0)
                                                setChartData(null)
                                                setInterestRate(e.target.value)
                                            }} />
                                            <Slider
                                                value={interestRate}
                                                min={5}
                                                max={20}
                                                step={0.1}
                                                marks={rateLabels}
                                                vertical={false}
                                                onChange={value => {
                                                    setResultAmount(0)
                                                    setChartData(null)
                                                    setInterestRate(Math.round(value * 10) / 10)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="p-3">
                                            <h5 className="font-size-14 mb-3 mt-0">
                                                {investmentName === 'EMI' ? 'Loan' : 'Investment'} Term (in years):
                                            </h5>
                                            <Input type="number" name="loanTerm" placeholder="Enter Term" value={loanTerm} onChange={(e) => {
                                                setResultAmount(0)
                                                setChartData(null)
                                                setLoanTerm(e.target.value)
                                            }} />
                                            <Slider
                                                value={loanTerm}
                                                min={0}
                                                max={50}
                                                step={0.5}
                                                marks={termLabels}
                                                vertical={false}
                                                onChange={value => {
                                                    setResultAmount(0)
                                                    setChartData(null)
                                                    setLoanTerm(value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CalResultDialogue isOpen={isOpen} onClose={setIsOpen} chartData={chartData} data={calculatedData}/>
            
                            <CardFooter>
                                <div className="d-grid gap-2">
                                    {resultAmount && <h1 className="bg-warning">Your {investmentName}: {resultAmount}</h1>}
                                    <Button color="dark" type="button" onClick={() =>{ 
                                        callBackCalculator()                                        
                                        setIsOpen(true)
                                    }} 
                                        className="btn-lg">Calculate {investmentName}</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col className="col-4" style={{ padding: "0" }}>
                        <Card className="bg-dark" style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <h1 className="text-light">{investmentName} Calculator</h1>
                            <div className="mt-4 mt-md-0" style={{ width: "80%" }}>
                                <img className="img-thumbnail rounded-circle avatar-xl" alt="200x200" src={EmiImg} data-holder-rendered="true" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </BrowserView>
            <MobileView>
                <Row>
                    <Col className="col-12" style={{ padding: "0" }}>
                        <Card className="bg-dark" style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <h1 className="text-light">{investmentName} Calculator</h1>
                            <div className="mt-4 mt-md-0" style={{ width: "80%" }}>
                                <img className="img-thumbnail rounded-circle avatar-xl" alt="200x200" src={EmiImg} data-holder-rendered="true" />
                            </div>
                        </Card>
                    </Col>
                    <Col className="col-12" style={{ padding: "0" }}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <div className="p-3">
                                            <h5 className="font-size-14 mb-3 mt-0">
                                                {investmentName === 'EMI' ? 'Loan Amount:' : 'Principal Amount:'}
                                            </h5>
                                            <Input type="number" name="amount" placeholder="Enter Amount" value={loanAmount} onChange={(e) => {
                                                setResultAmount(0)
                                                setChartData(null)
                                                setLoanAmount(e.target.value)
                                            }}
                                            />
                                            <Slider
                                                value={loanAmount / 100000}
                                                min={0}
                                                max={200}
                                                step={5}
                                                marks={amountLabels}
                                                vertical={false}
                                                onChange={value => {
                                                    setResultAmount(0)
                                                    setChartData(null)
                                                    setLoanAmount(value * 100000)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="p-3">
                                            <h5 className="font-size-14 mb-3 mt-0">
                                                Interest Rate (% per annum):
                                            </h5>
                                            <Input type="number" name="interestRate" placeholder="Enter Interest Rate" value={interestRate} onChange={(e) => {
                                                setResultAmount(0)
                                                setChartData(null)
                                                setInterestRate(e.target.value)
                                            }} />
                                            <Slider
                                                value={interestRate}
                                                min={5}
                                                max={20}
                                                step={0.1}
                                                marks={rateLabels}
                                                vertical={false}
                                                onChange={value => {
                                                    setResultAmount(0)
                                                    setChartData(null)
                                                    setInterestRate(Math.round(value * 10) / 10)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="p-3">
                                            <h5 className="font-size-14 mb-3 mt-0">
                                                {investmentName === 'EMI' ? 'Loan' : 'Investment'} Term (in years):
                                            </h5>
                                            <Input type="number" name="loanTerm" placeholder="Enter Term" value={loanTerm} onChange={(e) => {
                                                setResultAmount(0)
                                                setChartData(null)
                                                setLoanTerm(e.target.value)
                                            }} />
                                            <Slider
                                                value={loanTerm}
                                                min={0}
                                                max={50}
                                                step={0.5}
                                                marks={termLabels}
                                                vertical={false}
                                                onChange={value => {
                                                    setResultAmount(0)
                                                    setChartData(null)
                                                    setLoanTerm(value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CalResultDialogue isOpen={isOpen} onClose={setIsOpen} chartData={chartData} data={calculatedData}/>
                            <CardFooter>
                                <div className="d-grid gap-2">
                                    {resultAmount && <h1 className="bg-warning">Your {investmentName}: {resultAmount}</h1>}
                                    <Button color="dark" type="button" onClick={() =>{ 
                                        callBackCalculator()
                                        setIsOpen(true)
                                        }} className="btn-lg">Calculate {investmentName}</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </MobileView>
        </div>
    );
};

export default Calculator;