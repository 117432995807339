import React, { useState } from "react";
import {
  Row, Col, Card, CardBody, CardTitle, Button, CardFooter, Input, Collapse, Container, Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledCollapse,
} from "reactstrap"
import classnames from "classnames";
import Footer from "../../components/Footer";
import Calculator from "./Calculator";

const FinanceCalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [emi, setEmi] = useState(0);
  const [sipReturns, setSipReturns] = useState(0);
  const [fdReturns, setFdReturns] = useState(0);
  const [rdReturns, setRdReturns] = useState(0);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [chartData, setChartData] = useState(null);
  const [calculatedData,setCalculatedData]=useState({})

  const generateChartData = (amount, investmentName, investmentValue) => {
    const data = [
      { x: 'Principal/Amount', y: amount },
      { x: investmentName, y: investmentValue },
    ];
    setChartData(data);
  };

  const handleCalculation =(investmentName,resultAmount)=>{
    const data = { 
      "investmentName" : investmentName,
      "Term" : loanTerm,
      "Rate" : interestRate,
      "Amount" : loanAmount,
      "result" : resultAmount
    }
    setCalculatedData(data)
}

  const calculateEMI = () => {
    console.log("inside EMI cal")
    const principal = parseFloat(loanAmount);
    const rate = parseFloat(interestRate) / 100 / 12; // monthly interest rate
    const time = parseFloat(loanTerm) * 12; // loan term in months

    if (principal > 0 && rate > 0 && time > 0) {
      const emiValue = (principal * rate * Math.pow(1 + rate, time)) / (Math.pow(1 + rate, time) - 1);
      const result = Math.round(emiValue)
      setEmi(Math.round(emiValue));      
      handleCalculation("EMI",result)
      generateChartData(principal,'EMI', result)
    } else {
      setEmi(0);
    }
  };

  const resetValues = () => {
    setEmi(0)
    setSipReturns(0)
    setRdReturns(0)
    setFdReturns(0)
    setLoanAmount('')
    setInterestRate('')
    setLoanTerm('')
    setChartData(null)
    setCalculatedData({})
  }

  const calculateSIPReturns = () => {
    const totalInvestment = loanAmount * loanTerm;
    const returns = totalInvestment + (totalInvestment * interestRate * loanTerm) / 100;
    setSipReturns(returns);
    generateChartData(loanAmount,'SIP',returns)
    handleCalculation("SIP",returns)
  };

  const calculateFDReturns = () => {
    const returns = loanAmount + (loanAmount * interestRate * loanTerm) / 100;
    setFdReturns(returns);
    generateChartData(loanAmount,'FD',returns)
    handleCalculation("FD",returns)
  };

  const calculateRDReturns = () => {
    const monthlyInvestment = loanAmount / loanTerm;
    const returns = loanAmount + (monthlyInvestment * (loanTerm * (loanTerm + 1))) / 2;
    setRdReturns(returns);
    generateChartData(loanAmount,'RD',returns)
    handleCalculation("RD",returns)
  };

  const termLabels = {
    1: "1",
    5: "5",
    10: "10",
    15: "15",
    20: "20",
    25: "25",
    30: "30",
    35: "35",
    40: "40",
    45: "45",
    50: "50"
  }

  const rateLabels = {
    5: "5",
    7.5: "7.5",
    10: "10",
    12.5: "12.5",
    15: "15",
    17.5: "17.5",
    20: "20",
  }

  const amountLabels = {
    0: "0",
    25: "25L",
    50: "50L",
    75: "75L",
    100: "100L",
    125: "125L",
    150: "150L",
    175: "175L",
    200: "200L"
  }

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div>
      <Container className="bg-secondary" fluid={true}>
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "1",
              })}
              onClick={() => {
                resetValues()
                toggleCustom("1");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">EMI</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "2",
              })}
              onClick={() => {
                resetValues()
                toggleCustom("2");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-user"></i>
              </span>
              <span className="d-none d-sm-block">SIP</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "3",
              })}
              onClick={() => {
                resetValues()
                toggleCustom("3");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">FD</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "4",
              })}
              onClick={() => {
                resetValues()
                toggleCustom("4");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-cog"></i>
              </span>
              <span className="d-none d-sm-block">RD</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={customActiveTab}
          className="p-3 text-muted"
        >
          <TabPane tabId="1">
            <Calculator loanAmount={loanAmount} setLoanAmount={setLoanAmount} interestRate={interestRate}
              setInterestRate={setInterestRate} loanTerm={loanTerm} setLoanTerm={setLoanTerm} setChartData={setChartData}
              callBackCalculator={calculateEMI} amountLabels={amountLabels} rateLabels={rateLabels} chartData={chartData}
              termLabels={termLabels} investmentName='EMI' resultAmount={emi} setResultAmount={setEmi} calculatedData={calculatedData}
            />
          </TabPane>
          <TabPane tabId="2">
            <Calculator loanAmount={loanAmount} setLoanAmount={setLoanAmount} interestRate={interestRate}
              setInterestRate={setInterestRate} loanTerm={loanTerm} setLoanTerm={setLoanTerm} setChartData={setChartData}
              callBackCalculator={calculateSIPReturns} amountLabels={amountLabels} rateLabels={rateLabels} chartData={chartData}
              termLabels={termLabels} investmentName='SIP' resultAmount={sipReturns} setResultAmount={setSipReturns} calculatedData={calculatedData}
            />
          </TabPane>
          <TabPane tabId="3">
            <Calculator loanAmount={loanAmount} setLoanAmount={setLoanAmount} interestRate={interestRate}
              setInterestRate={setInterestRate} loanTerm={loanTerm} setLoanTerm={setLoanTerm} setChartData={setChartData}
              callBackCalculator={calculateFDReturns} amountLabels={amountLabels} rateLabels={rateLabels} chartData={chartData}
              termLabels={termLabels} investmentName='FD' resultAmount={fdReturns} setResultAmount={setFdReturns} calculatedData={calculatedData}
            />
          </TabPane>
          <TabPane tabId="4">
            <Calculator loanAmount={loanAmount} setLoanAmount={setLoanAmount} interestRate={interestRate}
              setInterestRate={setInterestRate} loanTerm={loanTerm} setLoanTerm={setLoanTerm} setChartData={setChartData}
              callBackCalculator={calculateRDReturns} amountLabels={amountLabels} rateLabels={rateLabels} chartData={chartData}
              termLabels={termLabels} investmentName='RD' resultAmount={rdReturns} setResultAmount={setRdReturns} calculatedData={calculatedData}
            />
          </TabPane>
        </TabContent>
      </Container>
      <Footer />
    </div>
  );
};

export default FinanceCalculator;


