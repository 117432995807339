import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { VictoryPie } from 'victory';

const CalResultDialogue = ({ isOpen, onClose, chartData, data }) => {
    console.log("data is", data)
    return (
        <Modal
            show={isOpen}
            toggle={() => {
                onClose(false);
            }}
            scrollable={true}
        ><div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {data?.investmentName}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        onClose(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body d-flex gap-4">
                {chartData && (
                    <div style={{ width: "200px" }}>
                        <VictoryPie data={chartData} colorScale={['#ff1c07', '#df1b2e',]} innerRadius={80} />
                    </div>
                )}
                {data && <div className="d-grid text-center text-bold rounded overflow-hidden">
                    <span className="h4" style={{ color: "#ff1c07" }}>{data?.investmentName} : {data?.result}</span>
                    <span className="h4" style={{ color: "#df1b2e" }}>Amount : {data?.Amount}</span>
                    <span className="h4" style={{ color: "#f1b44c" }}>Rate : {data?.Rate}</span>
                    <span className="h4" style={{ color: "#50a5f1" }}>Term : {data?.Term}</span>

                </div>}


            </div>
        </Modal>
    );
};

export default CalResultDialogue;
